import React from 'react';
import Text from '../ui/Text';

interface PricingSectionNameProps {
  children: string;
  highlighted?: boolean;
}

const PricingSectionName: React.SFC<PricingSectionNameProps> = ({
  children,
  highlighted,
}) => (
  <Text xxSmall caps darker={!highlighted} white={highlighted} bold>
    {children}
  </Text>
);

export default PricingSectionName;
