import classNames from 'classnames';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { BillingCycle } from '../Prices';
import styles from './PricingController.module.scss';

export interface PricingControllerProps {
  billingCycle: BillingCycle;
  onCycleChange: (value: BillingCycle) => void;
}

const PricingController: React.SFC<PricingControllerProps> = ({
  billingCycle,
  onCycleChange,
}) => {
  const isAnnually = billingCycle === 'annually';
  const [activeBackgroundStyle, setActiveBackgroundStyle] = useState({
    left: 0,
    width: 0,
  });
  const IsActiveBackgroundInitializedRef = useRef(false);
  const annuallyRef = useRef<HTMLDivElement>(null);
  const monthlyRef = useRef<HTMLDivElement>(null);

  const setBackgroundSize = () => {
    const activeRef = isAnnually ? annuallyRef : monthlyRef;
    const element = activeRef.current;

    if (element?.offsetWidth) {
      setActiveBackgroundStyle({
        left: element.offsetLeft,
        width: element.offsetWidth,
      });
    }
  };

  useLayoutEffect(() => {
    setBackgroundSize();
    window.addEventListener('resize', setBackgroundSize, { passive: true });
    IsActiveBackgroundInitializedRef.current = !!activeBackgroundStyle.width;

    return () => window.removeEventListener('resize', setBackgroundSize);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnnually]);

  const activeProps = {
    className: classNames(styles.active, styles.option),
  };

  const inactiveProps = {
    className: styles.option,
  };

  return (
    <div className={styles.container}>
      <label className={styles.control}>
        <input
          type="checkbox"
          name="billingAnnually"
          checked={isAnnually}
          aria-checked={isAnnually}
          onChange={(e) => {
            onCycleChange(e.target.checked ? 'annually' : 'monthly');
          }}
        />
        <div
          className={classNames(styles.activeBackground, {
            [styles.noTransition]: !IsActiveBackgroundInitializedRef.current,
          })}
          style={{
            left: activeBackgroundStyle.left,
            width: activeBackgroundStyle.width,
          }}
        />
        <div {...(isAnnually ? activeProps : inactiveProps)} ref={annuallyRef}>
          Annually
        </div>
        <div {...(isAnnually ? inactiveProps : activeProps)} ref={monthlyRef}>
          Monthly
        </div>
      </label>
      <div className={styles.promoText}>
        {!isAnnually && 'Pay annually for 2 months free'}
      </div>
    </div>
  );
};

export default PricingController;
