import React from 'react';
import PageHeader from '../layout/PageHeader';

const PricingHeader: React.SFC = () => {
  return (
    <PageHeader
      title="Plans that scale with your team"
      description="Use Slab free forever. No credit card required."
    />
  );
};

export default PricingHeader;
