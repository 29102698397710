import React, { ReactNode } from 'react';
import styles from './PricingPlanHeaderFinePrint.module.scss';

interface PricingPlanHeaderFinePrintProps {
  children: ReactNode;
}

const PricingPlanHeaderFinePrint: React.SFC<
  PricingPlanHeaderFinePrintProps
> = ({ children }) => <div className={styles.finePrint}>{children}</div>;

export default PricingPlanHeaderFinePrint;
