import React, { ReactNode } from 'react';
import ComparisonValue from '../ComparisonValue';
import PricingFeatureName from '../PricingFeatureName';
import styles from './PricingTableRow.module.scss';

type CellValue = string | boolean;

interface PricingTableRowProps {
  name: string;
  tooltip?: ReactNode;
  values: CellValue[];
}

const PricingTableRow: React.SFC<PricingTableRowProps> = ({
  name,
  tooltip,
  values,
}) => (
  <tr className={styles.row}>
    <th className={styles.header}>
      <PricingFeatureName
        tooltip={tooltip}
        underline={!!tooltip}
        tooltipPlacement="right"
      >
        {name}
      </PricingFeatureName>
    </th>
    {values.map((value, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <td className={styles.value} key={i}>
        <ComparisonValue>{value}</ComparisonValue>
      </td>
    ))}
  </tr>
);

export default PricingTableRow;
