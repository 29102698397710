import React, { ReactNode } from 'react';
import { Manager, Reference } from 'react-popper';
import Text from '../ui/Text';
import TooltipPopper from '../ui/TooltipPopper';
import styles from './PricingFeatureName.module.scss';

interface PricingFeatureNameProps {
  children: string;
  tooltip?: ReactNode;
  underline?: boolean;
  tooltipPlacement?: 'right' | 'bottom-start';
  highlighted?: boolean;
}

interface PricingFeatureNameState {
  showTooltip: boolean;
}

class PricingFeatureName extends React.Component<
  PricingFeatureNameProps,
  PricingFeatureNameState
> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state: PricingFeatureNameState = {
    showTooltip: false,
  };

  handleToggleTooltip = () => {
    this.setState({ showTooltip: !this.state.showTooltip });
  };

  render() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { children, highlighted, tooltip, underline, tooltipPlacement } =
      this.props;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { showTooltip } = this.state;

    return (
      <div
        className={styles.container}
        onMouseEnter={this.handleToggleTooltip}
        onMouseLeave={this.handleToggleTooltip}
      >
        <Manager>
          <Reference>
            {({ ref }) => (
              <Text xSmall semiBold darker={!highlighted} white={highlighted}>
                <span
                  className={underline ? styles.underline : undefined}
                  ref={ref}
                >
                  {children}
                </span>
              </Text>
            )}
          </Reference>
          {showTooltip && tooltip && (
            <TooltipPopper placement={tooltipPlacement}>
              <div className={styles.tooltip}>{tooltip}</div>
            </TooltipPopper>
          )}
        </Manager>
      </div>
    );
  }
}

export default PricingFeatureName;
