import React from 'react';
import RegisterForm from '../../account/RegisterForm';
import Button from '../../ui/Button';
import styles from './PricingTableActionRow.module.scss';

const PricingTableActionRow: React.SFC = () => {
  const isCompact = typeof window !== 'undefined' && window.innerWidth < 1280;
  const buttonProps = {
    block: true,
    linkUnderline: isCompact,
    primary: false,
    short: true,
  };

  return (
    <tr>
      <td />
      <td className={styles.actionCell}>
        <RegisterForm
          buttonProps={buttonProps}
          hideEmailInput
          source="pricing-free"
          color="gray"
        />
      </td>
      <td className={styles.actionCell}>
        <RegisterForm
          buttonProps={buttonProps}
          color="yellow"
          hideEmailInput
          source="pricing-startup"
        />
      </td>
      <td className={styles.actionCell}>
        <RegisterForm
          buttonProps={buttonProps}
          color="blue"
          hideEmailInput
          source="pricing-business"
        />
      </td>
      <td className={`${styles.actionCell} ${styles.maroon}`}>
        <Button
          color={isCompact ? 'white' : 'maroon'}
          to="/contact-sales/"
          target="_blank"
          {...buttonProps}
        >
          Contact Sales
        </Button>
      </td>
    </tr>
  );
};

export default PricingTableActionRow;
