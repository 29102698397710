import React from 'react';
import PageSection from '../../ui/PageSection';
import Spacing from '../../ui/Spacing';
import { PricingControllerProps } from '../headers/PricingController';
import { PlanFeatures } from '../PricingContent';
import PricingPlanName from '../PricingPlanName';
import styles from './PricingTable.module.scss';
import PricingTableFooter from './PricingTableFooter';
import PricingTablePriceTop from './PricingTablePriceTop';
import PricingTableRow from './PricingTableRow';
import PricingTableSectionHeader from './PricingTableSectionHeader';

interface PricingTableProps {
  planFeatures: PlanFeatures;
  pricingControls: PricingControllerProps;
}

const PricingTable: React.SFC<PricingTableProps> = ({
  planFeatures,
  pricingControls,
}) => (
  <Spacing vertical={10}>
    <div className={styles.container}>
      <PageSection>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead className={styles.planNameRow}>
              <tr>
                <th />
                <th>
                  <PricingPlanName>Free</PricingPlanName>
                </th>
                <th>
                  <PricingPlanName>Startup</PricingPlanName>
                </th>
                <th>
                  <PricingPlanName>Business</PricingPlanName>
                </th>
                <th>
                  <PricingPlanName>Enterprise</PricingPlanName>
                </th>
              </tr>
            </thead>
            <PricingTablePriceTop pricingControls={pricingControls} />
            {planFeatures.map(([sectionName, features]) => (
              <tbody key={sectionName}>
                <PricingTableSectionHeader>
                  {sectionName}
                </PricingTableSectionHeader>
                {features.map(([[name, tooltip], ...values]) => (
                  <PricingTableRow
                    key={name}
                    name={name}
                    tooltip={tooltip}
                    values={values}
                  />
                ))}
              </tbody>
            ))}
            <PricingTableFooter />
          </table>
        </div>
      </PageSection>
    </div>
  </Spacing>
);

export default PricingTable;
