import React from 'react';
import Check from './Check';
import Strike from './Strike';

interface ComparisonValueProps {
  children: React.ReactNode;
}

const ComparisonValue: React.SFC<ComparisonValueProps> = ({ children }) => {
  if (children === true) {
    return <Check />;
  }
  if (children === false) {
    return <Strike />;
  }
  return <>{children}</>;
};

export default ComparisonValue;
