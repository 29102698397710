import classNames from 'classnames';
import React from 'react';
import { AccentColors } from '../../../types';
import Prices, { BillingCycle, Plan } from '../Prices';
import styles from './PricingPlanHeader.module.scss';
import PricingPlanHeaderFinePrint from './PricingPlanHeaderFinePrint';

interface PricingPlanHeaderProps {
  billingCycle: BillingCycle;
  color?: AccentColors;
  plan: Plan;
}

const getNumberString = (price: number) =>
  price.toLocaleString
    ? price.toLocaleString(undefined, {
        minimumFractionDigits: Number.isInteger(price) ? undefined : 2,
      })
    : price.toString();

const PricingPlanHeader: React.SFC<PricingPlanHeaderProps> = ({
  billingCycle,
  color,
  plan,
}) => (
  <div className={classNames(styles.pricingPlanHeader, color && styles[color])}>
    <div className={styles.priceContainer}>
      <div className={styles.prefix}>$</div>
      <div className={styles.price}>
        {getNumberString(Prices[plan][billingCycle])}
      </div>
    </div>
    <PricingPlanHeaderFinePrint>
      user / month
      {plan !== 'free' && (
        <>
          <br />
          billed {billingCycle}
        </>
      )}
    </PricingPlanHeaderFinePrint>
  </div>
);

export default PricingPlanHeader;
