export type Plan = 'free' | 'startup' | 'business';
export type BillingCycle = 'annually' | 'monthly';

interface PricePlan {
  annually: number;
  monthly: number;
}

interface PricePlans {
  free: PricePlan;
  startup: PricePlan;
  business: PricePlan;
}

const Prices: PricePlans = {
  free: { annually: 0, monthly: 0 },
  startup: { annually: 6.67, monthly: 8.0 },
  business: { annually: 12.5, monthly: 15.0 },
};

export default Prices;
