import classNames from 'classnames';
import React from 'react';
import PricingController, {
  PricingControllerProps,
} from '../headers/PricingController';
import PricingPlanCallHeader from '../headers/PricingPlanCallHeader';
import PricingPlanHeader from '../headers/PricingPlanHeader';
import PricingTableActionRow from './PricingTableActionRow';
import styles from './PricingTablePriceTop.module.scss';

interface PricingTablePriceTopProps {
  pricingControls: PricingControllerProps;
}

const PricingTablePriceTop: React.SFC<PricingTablePriceTopProps> = ({
  pricingControls: { billingCycle, onCycleChange },
}) => (
  <thead className={styles.priceTop}>
    <tr>
      <td>
        <PricingController
          billingCycle={billingCycle}
          onCycleChange={onCycleChange}
        />
      </td>
      <td className={styles.priceCell}>
        <PricingPlanHeader
          billingCycle={billingCycle}
          plan="free"
          color="gray"
        />
      </td>
      <td className={styles.priceCell}>
        <PricingPlanHeader
          billingCycle={billingCycle}
          color="yellow"
          plan="startup"
        />
      </td>
      <td className={styles.priceCell}>
        <PricingPlanHeader
          billingCycle={billingCycle}
          color="blue"
          plan="business"
        />
      </td>
      <td className={classNames(styles.priceCell, styles.highlighted)}>
        <PricingPlanCallHeader />
      </td>
    </tr>
    <PricingTableActionRow />
  </thead>
);

export default PricingTablePriceTop;
