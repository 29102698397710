import React from 'react';
import PricingTableActionRow from './PricingTableActionRow';
import styles from './PricingTableFooter.module.scss';

const PricingTableFooter: React.SFC = () => (
  <tfoot className={styles.footer}>
    <PricingTableActionRow />
  </tfoot>
);

export default PricingTableFooter;
