import React from 'react';
import MarketingLayout from '../components/layout/MarketingLayout';
import PricingContent from '../components/pricing/PricingContent';
import PricingFaq from '../components/pricing/PricingFaq';
import PricingHeader from '../components/pricing/PricingHeader';

const Pricing: React.SFC = () => (
  <MarketingLayout title="Pricing">
    <PricingHeader />
    <PricingContent />
    <PricingFaq />
  </MarketingLayout>
);

export default Pricing;
