import classNames from 'classnames';
import React, { ReactNode } from 'react';
import Caret from '../../ui/Caret';
import Spacing from '../../ui/Spacing';
import Text from '../../ui/Text';
import { PlanFeatures } from '../PricingContent';
import PricingPlanName from '../PricingPlanName';
import PricingSectionName from '../PricingSectionName';
import styles from './PricingCard.module.scss';
import PricingCardRow from './PricingCardRow';

interface PricingCardProps {
  header: ReactNode;
  highlighted?: boolean;
  planName: string;
  planFeatures: PlanFeatures;
  planIndex: number;
}

interface PricingCardState {
  expanded: boolean;
}

class PricingCard extends React.Component<PricingCardProps, PricingCardState> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state: PricingCardState = {
    expanded: false,
  };

  handleExpandToggle = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { header, highlighted, planName, planFeatures, planIndex } =
      this.props;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { expanded } = this.state;

    return (
      <div>
        <Spacing bottom={2}>
          <PricingPlanName>{planName}</PricingPlanName>
        </Spacing>
        <div
          className={classNames(styles.card, {
            [styles.highlighted]: highlighted,
          })}
        >
          {header}
          {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
          <div role="button" onClick={this.handleExpandToggle}>
            <Spacing all={2}>
              <div
                className={classNames(styles.cardContent, {
                  [styles.expanded]: this.state.expanded,
                })}
              >
                {planFeatures.map(([sectionName, features]) => (
                  <div key={sectionName}>
                    <Spacing bottom={3}>
                      <div className={styles.sectionName}>
                        <PricingSectionName highlighted={highlighted}>
                          {sectionName}
                        </PricingSectionName>
                      </div>
                      {features.map(
                        ([[featureName, tooltip], ...planSupports]) => (
                          <PricingCardRow
                            key={featureName}
                            highlighted={highlighted}
                            name={featureName}
                            tooltip={tooltip}
                            value={planSupports[planIndex]}
                          />
                        ),
                      )}
                    </Spacing>
                  </div>
                ))}
              </div>
              <div className={styles.expandControl}>
                <div>
                  <Caret direction={expanded ? 'up' : 'right'} />
                </div>
                <Spacing left={1}>
                  <Text bold darker={!highlighted} white={highlighted}>
                    {expanded ? 'Show Less' : 'Show More'}
                  </Text>
                </Spacing>
              </div>
            </Spacing>
          </div>
        </div>
      </div>
    );
  }
}

export default PricingCard;
