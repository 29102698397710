import React from 'react';
import styles from './PricingPlanCallHeader.module.scss';
import PricingPlanHeaderFinePrint from './PricingPlanHeaderFinePrint';

const PricingPlanCallHeader: React.SFC = () => (
  <div className={styles.callHeader}>
    <div className={styles.big}>Custom</div>
    <PricingPlanHeaderFinePrint>
      <a href="mailto:sales@slab.com">sales@slab.com</a>
    </PricingPlanHeaderFinePrint>
  </div>
);

export default PricingPlanCallHeader;
