import React, { ReactNode } from 'react';
import Spacing from '../../ui/Spacing';
import PricingFeatureName from '../PricingFeatureName';

interface PricingCardRowProps {
  highlighted?: boolean;
  name: string;
  tooltip?: ReactNode;
  value: string | boolean;
}

const PricingCardRow: React.SFC<PricingCardRowProps> = ({
  highlighted,
  name,
  tooltip,
  value,
}) => {
  if (!value) {
    return null;
  }

  return (
    <Spacing vertical={1}>
      <PricingFeatureName
        highlighted={highlighted}
        tooltip={tooltip}
        tooltipPlacement="bottom-start"
      >
        {value === true ? name : `${value} ${name}`}
      </PricingFeatureName>
    </Spacing>
  );
};

export default PricingCardRow;
