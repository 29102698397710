import React from 'react';
import Text from '../ui/Text';

interface PricingPlanNameProps {
  children: string;
}

const PricingPlanName: React.SFC<PricingPlanNameProps> = ({ children }) => (
  <Text center medium semiBold darker>
    {children}
  </Text>
);

export default PricingPlanName;
