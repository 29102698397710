import React from 'react';
import PageSection from '../../ui/PageSection';
import Spacing from '../../ui/Spacing';
import PricingController, {
  PricingControllerProps,
} from '../headers/PricingController';
import PricingPlanCallHeader from '../headers/PricingPlanCallHeader';
import PricingPlanHeader from '../headers/PricingPlanHeader';
import { PlanFeatures } from '../PricingContent';
import PricingCard from './PricingCard';
import styles from './PricingCards.module.scss';

interface PricingCardsProps {
  planFeatures: PlanFeatures;
  pricingControls: PricingControllerProps;
}

const PricingCards: React.SFC<PricingCardsProps> = ({
  planFeatures,
  pricingControls,
}) => {
  const { billingCycle } = pricingControls;

  return (
    <Spacing vertical={5}>
      <PageSection background="light">
        <div className={styles.container}>
          <div className={styles.controllerContainer}>
            <PricingController {...pricingControls} />
          </div>
          <Spacing top={5}>
            <PricingCard
              header={
                <PricingPlanHeader
                  color="gray"
                  billingCycle={billingCycle}
                  plan="free"
                />
              }
              planName="Free"
              planFeatures={planFeatures}
              planIndex={0}
            />
          </Spacing>
          <Spacing top={5}>
            <PricingCard
              header={
                <PricingPlanHeader
                  color="yellow"
                  billingCycle={billingCycle}
                  plan="startup"
                />
              }
              planName="Startup"
              planFeatures={planFeatures}
              planIndex={1}
            />
          </Spacing>
          <Spacing top={5}>
            <PricingCard
              header={
                <PricingPlanHeader
                  color="blue"
                  billingCycle={billingCycle}
                  plan="business"
                />
              }
              planName="Business"
              planFeatures={planFeatures}
              planIndex={2}
            />
          </Spacing>
          <Spacing top={5}>
            <PricingCard
              header={<PricingPlanCallHeader />}
              highlighted
              planName="Enterprise"
              planFeatures={planFeatures}
              planIndex={3}
            />
          </Spacing>
        </div>
      </PageSection>
    </Spacing>
  );
};

export default PricingCards;
