import React from 'react';
import styles from './PricingTableSectionHeader.module.scss';

interface PricingTableSectionHeaderProps {
  children: string;
}

const PricingTableSectionHeader: React.SFC<PricingTableSectionHeaderProps> = ({
  children,
}) => (
  <tr className={styles.sectionHeader}>
    <th>
      <div className={styles.sectionHeaderText}>{children}</div>
    </th>
    <td />
    <td />
    <td />
    <td />
  </tr>
);

export default PricingTableSectionHeader;
