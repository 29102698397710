import React from 'react';

const Check: React.SFC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <g strokeWidth="2">
        <polyline
          transform="rotate(135 19.5 16)"
          points="10 12 29 12.0025785 29 20"
        />
        <circle cx="19" cy="19" r="18" />
      </g>
    </g>
  </svg>
);

export default Check;
