import React from 'react';
import showIntercom from '../../utils/showIntercom';
import Button from '../ui/Button';
import Link from '../ui/Link';
import PageSection from '../ui/PageSection';
import Row from '../ui/Row';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import styles from './PricingFaq.module.scss';

const PricingFaq: React.SFC = () => (
  <PageSection>
    <Row even>
      <div>
        <div className={styles.header}>
          <h3 id="faq">Frequently Asked Questions</h3>
          <Spacing top={4} bottom={2}>
            <Text bold darker small>
              Not seeing your question here?
            </Text>
          </Spacing>
          <Text bold small>
            <Spacing vertical={1}>
              <Button unstyled to="mailto:support@slab.com" target="_blank">
                <Text semiBold link heavyUnderline>
                  Email us
                </Text>
              </Button>
            </Spacing>
            <Spacing vertical={1}>
              <Button unstyled onClick={showIntercom}>
                <Text semiBold link heavyUnderline>
                  Chat with us
                </Text>
              </Button>
            </Spacing>
          </Text>
        </div>
      </div>
      <div>
        <Spacing bottom={6}>
          <Spacing bottom={2}>
            <Text bold darker>
              Can I use Slab for free?
            </Text>
          </Spacing>
          <Text small>
            Yes! Slab is free to for up to 10 users + guests forever. We think
            you&apos;ll love it, whichever plan you are on!
          </Text>
        </Spacing>
        <Spacing bottom={6}>
          <Spacing bottom={2}>
            <Text bold darker>
              Are there discounts for non-profits or educational use?
            </Text>
          </Spacing>
          <Text small>
            Yes! Slab&apos;s Startup plan is free for non-profits and
            educational use. Register for a{' '}
            <Link to="/register/" track={{ label: 'pricing-faq' }}>
              <Text inline link>
                free account
              </Text>
            </Link>{' '}
            and then{' '}
            <Link to="/nonprofit-and-educational-discount/">
              <Text inline link>
                request the discount
              </Text>
            </Link>
            .
          </Text>
        </Spacing>
        <Spacing bottom={6}>
          <Spacing bottom={2}>
            <Text bold darker>
              How are users counted for billing?
            </Text>
          </Spacing>
          <Text small>
            You will be billed for the number of users who can log into Slab.
            Admins may deactivate users at any time.
          </Text>
        </Spacing>
        <Spacing bottom={6}>
          <Spacing bottom={2}>
            <Text bold darker>
              How are guests different from regular users?
            </Text>
          </Spacing>
          <Text small>
            Guests only have access to the topics, and posts within topics, that
            they are a member of. Regular users by default have access to all
            open topics and published posts.{' '}
            <a
              href="https://help.slab.com/en/articles/2849252-guest-accounts"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text inline link>
                Learn more about guests
              </Text>
            </a>
            .
          </Text>
        </Spacing>
        <Spacing bottom={6}>
          <Spacing bottom={2}>
            <Text bold darker>
              Are there readonly users?
            </Text>
          </Spacing>
          <Text small>
            No, edit access is based on how content is organized and shared.
            Slab does not have a user account that inherently cannot edit
            anything.
          </Text>
        </Spacing>
        <Spacing bottom={6}>
          <Spacing bottom={2}>
            <Text bold darker>
              What happens if I am not satisfied with Slab?
            </Text>
          </Spacing>
          <Text small>
            We offer a 30-day money-back guarantee. No questions asked.
          </Text>
        </Spacing>
      </div>
    </Row>
  </PageSection>
);

export default PricingFaq;
